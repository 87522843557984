
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code4 } from "./data";

export default defineComponent({
  name: "month-range",
  data() {
    return {
      shortcuts: [
        {
          text: "This month",
          value: [new Date(), new Date()]
        },
        {
          text: "This year",
          value: (() => {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            return [start, end];
          })()
        },
        {
          text: "Last 6 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            return [start, end];
          })()
        }
      ],
      value1: "",
      value2: ""
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return { code4 };
  }
});
