
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code6 } from "./data";

export default defineComponent({
  name: "default-time-for-start-date-and-end-date",
  data() {
    return {
      value: "",
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
      ] // '00:00:00', '23:59:59'
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code6
    };
  }
});
